<template>
  <div class="adminTrainingsTable">
    <table>
      <tr>
        <th>{{translation("global_input-training-id-label")}}</th>
        <th>{{translation("global_input-training-title-label")}}</th>
        <th>{{translation("global_input-date-from-label")}}</th>
        <th>{{translation("global_input-date-to-label")}}</th>
        <th>{{translation("global_input-mpsv-date-to-label")}}</th>
        <th>{{translation("global_input-price-label")}}</th>
        <th>{{translation("global_input-mpsv-price-label")}}</th>
        <th>{{translation("global_input-mpsv-url-label")}}</th>
        <th>{{translation("global_input-registration-count-label")}}</th>
      </tr>
      <tr 
        v-for="training in trainings.data" 
        :key="training.id"
        @click="handleRowClick(training.id)"
        class="clickable-row"
      >
        <td>{{ training.id }}</td>
        <td>{{ training.title }}</td>
        <td>{{ training.date_from }}</td>
        <td>{{ training.date_to }}</td>
        <td>{{ formatDate(training.mpsv_date_to) }}</td>
        <td>{{ training.price }}</td>
        <td>{{ training.mpsv_price }}</td>
        <td>{{ training.mpsv_url }}</td>
        <td>{{ training.registrations }}</td>
      </tr>
    </table>
  </div>
  <div class="adminTrainingsTable" v-if="selectedTrainingId">
    <table>
      <tr>
        <th>{{translation("global_input-lastname-label")}}</th>
        <th>{{translation("global_input-firstname-label")}}</th>
        <th>{{translation("global_input-email-label")}}</th>
        <th>{{translation("global_input-phone-label")}}</th>
        <th>{{translation("global_input-mpsv-active-label")}}</th>
        <th>{{translation("global_input-comment-label")}}</th>
        <th>{{translation("global_input-comment-up-label")}}</th>
        <th>{{translation("global_input-motivation-label")}}</th>
        <th>{{translation("global_input-highest-education-label")}}</th>
        <th>{{translation("global_input-field-of-study-label")}}</th>
        <th>{{translation("global_input-language-edu-label")}}</th>
        <th>{{translation("global_input-mpsv-url-label")}}</th>
        <th>{{translation("global_input-origin-label")}}</th>
        <th>{{translation("global_input-user-interest-status-label")}}</th>
      </tr>
      <tr v-for="user in users" :key="user.id">
        <td>
          <span v-if="!user.isEditing">{{ user.lastname }}</span>
          <input v-else v-model="user.lastname">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.firstname }}</span>
          <input v-else v-model="user.firstname">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.email }}</span>
          <input v-else v-model="user.email">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.phone }}</span>
          <input v-else v-model="user.phone">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.mpsv_active }}</span>
          <div v-else class="baseCheckbox">
            <input id="agree" type="checkbox" v-model="user.mpsv_active">
            <label for="agree"></label>
          </div>
        </td>
        <td>
          <span v-if="!user.isEditing" class="scrollableCell">{{ user.comment }}</span>
          <textarea v-else v-model="user.comment"></textarea>
        </td>
        <td>
          <span v-if="!user.isEditing" class="scrollableCell">{{ user.comment_up }}</span>
          <textarea v-else v-model="user.comment_up"></textarea>
        </td>
        <td>
          <span v-if="!user.isEditing" class="scrollableCell">{{ user.motivation }}</span>
          <textarea v-else v-model="user.motivation"></textarea>
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.highest_edu }}</span>
          <input v-else v-model="user.highest_edu">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.field }}</span>
          <input v-else v-model="user.field">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.language_edu }}</span>
          <input v-else v-model="user.language_edu">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ user.mpsv_url }}</span>
          <input v-else v-model="user.mpsv_url">
        </td>
        <td>
          <span v-if="!user.isEditing">{{ getCodeBookByKeyAndValue('user_origin', user.origin) }} </span>
          <div v-else class="adminTrainingsTable__select">
            <select v-model="user.origin">
              <option v-for="origin in selectUserOriginOptions" :value="origin.id">{{ origin.value }}</option>
            </select>
            <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" class="baseSelect__icon"><path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"></path></svg>
          </div>
        </td>
        <td>
          <span v-if="!user.isEditing">{{ getCodeBookByKeyAndValue('user_interest_state', user.interest_state) }}</span>
          <div v-else class="adminTrainingsTable__select">
            <select v-model="user.interest_state">
              <option v-for="intereststate in selectInterestStateOptions" :value="intereststate.id">{{ intereststate.value }}</option>
            </select>
            <svg width="15" height="9" viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg" class="baseSelect__icon"><path d="M0 1.834 1.876 0l5.625 5.4L13.126 0 15 1.834 7.501 9z" fill-rule="nonzero"></path></svg>
          </div>
        </td>
        <td>
          <div class="adminTrainingsTable__buttons">
            <button v-if="!user.isEditing" @click="editUser(user)" class="btn btn--sm btn--primary"><span>Edit</span></button>
            <button v-if="!user.isEditing" @click="confirmRemoveUser(user)" class="btn btn--sm btn--primary"><span>Delete</span></button>
            <button v-else @click="saveUser(user)" class="btn btn--sm btn--primary"><span>Save</span></button>
            <button v-if="user.isEditing" @click="cancelEdit(user)" class="btn btn--sm btn--primary"><span>Cancel</span></button>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div v-if="selectedTrainingId">
    <TrainingUserForm :trainingId="selectedTrainingId" />
  </div>
</template>

<script>
import { reactive } from "vue";
import formatMixins from "@/mixins/formatMixins";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import store from "@/store";
import { userService } from "@/services/user.service";
import TrainingUserForm from "@/components/TrainingUserForm.vue";
import BaseSelectBox from "@/components/BaseSelectBox.vue";

export default {
  props: {
    users: [],
    trainings: []
  },
  computed: {
    userorigin() {
        return this.getCodeBookByKey('user_origin');
      },
      selectUserOriginOptions() {
        return Object.keys(this.userorigin).map(key => {
          return {
            id: key,
            value: this.userorigin[key]
          };
        });
      },

      intereststates() {
        return this.getCodeBookByKey('user_interest_state');
      },
      selectInterestStateOptions() {
        return Object.keys(this.intereststates).map(key => {
          return {
            id: key,
            value: this.intereststates[key]
          };
        });
      }
  },
  data() {
    return {
      selectedTrainingId: null,
    };
  },
  mixins: [codeBooksMixin, formatMixins],
  methods: {

    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },

    save(data) {
      let userId = parseInt(data.id);
      const index = this.users.findIndex(row => {
        return row.id === userId;
      });
      let userData = this.users[index];
      console.log(JSON.stringify(userData));
    },
    handleRowClick(trainingId) {
      this.selectedTrainingId = trainingId;
      this.$emit('training-selected', trainingId);
    },
    editUser(user) {
      user.isEditing = true;
      user.backup = reactive({ ...user }); // create a backup of user data
    },
    async saveUser(user) {
      user.isEditing = false;
      delete user.backup;

      try {
        const editTrainingUsers = await userService.updateTrainingUser(
          user.id,
          user.training_id,
          user.user_id,
          user.firstname,
          user.lastname,
          user.email,
          user.phone,
          user.mpsv_active,
          user.comment,
          user.comment_up,
          user.motivation,
          user.highest_edu,
          user.field,
          user.language_edu,
          user.mpsv_url,
          user.origin,
          user.interest_state
        );
      } catch (err) {
        store.dispatch('app/addFlashMessage', { message: this.translation('global_alert-general') });
      }
    },
    cancelEdit(user) {
      Object.assign(user, user.backup); // restore backup
      user.isEditing = false;
      delete user.backup;
    },
    confirmRemoveUser(user) {
      if (confirm(`Are you sure you want to delete user ${user.firstname} ${user.lastname}?`)) {
        this.removeUser(user);
      }
    },
    async removeUser(user) {
      try {
        await userService.deleteTrainingUser(user.id);
        // Odstranit uživatele z lokálního stavu
        const index = this.users.findIndex(u => u.id === user.id);
        if (index !== -1) {
          this.users.splice(index, 1);  // odstraní uživatele z pole
        }
      } catch (err) {
        store.dispatch('app/addFlashMessage', { message: this.translation('global_alert-general') });
      }
    }
  },
  components: {
    TrainingUserForm,
    BaseSelectBox
  }
};
</script>