<template>
  <div class="indexReviewCarousel">
    <div class="indexReviewCarousel__wrapper">
      <div
          v-for="item in data"
          :key="item"
      >
        <IndexReviewCarouselItem
            :name="item.name"
            :image="item.image"
            :text="item.text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IndexReviewCarouselItem from "@/components/IndexReviewCarouselItem";
import {tns} from "tiny-slider";

export default {
  data() {
    return {
      carousel: null,
      data: [
        {
          name: 'Kamila Pilná',
          image: 'kamila-pilna.jpg',
          text: 'Před časem jsem dostala nabídku povýšení v mé současné práci ale pod podmínkou, že si doplním vzdělání a dostanu certifikát na MS Dynamics. Zapsala jsem se do ITjede.cz, prošla jsem si kurzy, měla jsem nějaký čas na přípravu a certifikát jsem si udělala. Co určitě oceňuji je to, že jsem si prohloubila dosavadní znalosti a načerpala dost tipů od profíků z Microsoftu. Za mě určitě super.',
        },
        {
          name: 'Petr Jaroš',
          image: 'petr-jaros.jpg',
          text: 'Doteď jsem se léta věnoval retailu a obchodu, ale o IT jsem nic moc nevěděl. Kdysi jsem se ale zapojil do implementace CRM u nás ve firmě a musím říct, že mě to poměrně zaujalo. Chtěl jsem se kariérně posunout, zajistit si nějakou rekvalifikaci, a tak jsem se přihlásil do ITjede.cz.  Během pár týdnů jsem si prošel několik kurzů a pak už mě konzultanti doporučili do firmy, kde jsem teď. Je ze mě IT konzultant. Za mě teda relativně rychlá cesta, jak kariéru nasměrovat do IT.',
        },
        {
          name: 'Karel Antoš',
          image: 'karel-antos.jpg',
          text: 'Díky ITjede.cz se mi podařilo získat pozici u jedné z předních IT společností na trhu. Rychle jsem získal povědomí o MS Dynamics 365 a využití technologií v praxi, což bylo zásadní, protože jsem dříve působil v jiném oboru.',
        },
        {
          name: 'Lada Kosová',
          image: 'lada-kosova.jpg',
          text: 'Často jsem slýchala, jak je IT lukrativní obor a říkala jsem si, že bych do toho taky mohla jít. Docela mě ale vyděsily ceny klasických rekvalifikačních kurzů a neměla jsem na studium stejně moc času. Pak jsem se doslechla o ITjede.cz a zkusila jsem to. Baví mě tam, že můžu studovat kdykoliv, odkudkoliv a vlastním tempem.',
        },
      ]
    }
  },
  mounted() {
    this.createCarousel();
  },
  methods: {
    createCarousel() {
      this.carousel = tns({
        container: '.indexReviewCarousel__wrapper',
        edgePadding: this.itemWidth,
        items: 1,
        gutter: 0,
        slideBy: 1,
        useLocalStorage: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        mouseDrag: true,
        center: true,
        controls: false,
        navPosition: 'bottom',
        responsive: {
          0: {
            edgePadding: 50,
            gutter: 30,
          },
          576: {
            edgePadding: 100,
            gutter: 70,
          },
          768: {
            edgePadding: 140,
          },
          992: {
            edgePadding: 300,
            gutter: 120,
          },
          1200: {
            edgePadding: 400,
          },
          1400: {
            edgePadding: 500,
          },
          1600: {
            edgePadding: 600,
          },
          1920: {
            edgePadding: this.countLargeWidth,
          }
        }
      });
    },
    destroyCarousel() {
      this.carousel.destroy();
    }
  },
  computed: {
    countLargeWidth() {
      return window.top.innerWidth * 35 / 100
    }
  },
  unmounted() {
    this.destroyCarousel();
  },
  components: {
    IndexReviewCarouselItem
  }
}
</script>